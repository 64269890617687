let resumeData = {
    "imagebaseurl":"https://wallpapers.com/images/featured/4k-tech-ulcajgzzc25jlrgi.webp",
    "name": "Enrique Leal",
    "role": "Data Scientist and GenAI developer",
    
    "skypeid": "Your skypeid",
    "roleDescription": " Motivated Mexican and Lithuanian professional with a strong foundation in Data Science, Data Engineering, and GenAI end to end implementations. Please try the chat to know me more",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/jose-enrique-leal/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"https://github.com/lealcastillo1996/lealcastillo1996",
          "className":"fa fa-github"
        },
     
      ],
    "aboutme":`I'm Enrique, a 27-year-old international professional with an entrepreneurial mindset. I came from Mexico to the Netherlands to stay updated on the latest IT advancements and immerse myself in the culture. This motivated me to complete my Master of Science in Applied Data Science with Cum Laude honors at Utrecht University."
    
    Helping people both inside and outside the office is something that always fulfills me. I'm passionate about sports, especially Football and F1. My current personal goal is to live and work as sustainably as possible to contribute to a better planet for future generations.`,
    "address":"Netherlands",
    "website":"lealcastillo1996@gmail.com",
    "education":[
      {
        "UniversityName":"Utrecht University",
        "specialization":"Master of Science in Applied Data Science",
        "YearOfPassing":"2023",
        GPA: 8.43,
        "Achievements":"Some Achievements"
      },
      {
        "UniversityName":"Some University",
        "specialization":"Some specialization",
        "MonthOfPassing":"Jan",
        "YearOfPassing":"2018",
        "Achievements":"Some Achievements"
      }
    ],
    "work":[
      {
        "CompanyName":"Some Company",
        "specialization":"Some specialization",
        "MonthOfLeaving":"Jan",
        "YearOfLeaving":"2018",
        "Achievements":"Some Achievements"
      },
      {
        "CompanyName":"Some Company",
        "specialization":"Some specialization",
        "MonthOfLeaving":"Jan",
        "YearOfLeaving":"2018",
        "Achievements":"Some Achievements"
      }
    ],
    "skillsDescription":"Your skills here",
    "skills":[
      {
        "skillname":"HTML5"
      },
      {
        "skillname":"CSS"
      },
      {
        "skillname":"Reactjs"
      }
    ],
    "portfolio":[
      {
        "link": "https://github.com/lealcastillo1996/Thesis_LLMs",
        "name":"Question answering system with LLMs. (June 2023) [NLP , AI, ML]",
        "description":" The chosen domain for the real-world QA task is cloud computing, focusing on Kubernetes technology. The QA system uses Kubernetes public documentation and real-time Google searches as its knowledge source. Performance evaluation is done using a Machine-trained evaluation score (MTES) called estimated human label (EHL), computed through an ML classification model. This model is trained using N-gram-based metrics. A carefully balanced dataset, labeled by human experts, includes various question categories. The research aims to enhance OS-powered QA systems and provide valuable insights into their performance factors by combining human expertise and MTES.",
        "imgurl":"images/llm.png"
      },
      {
        "link": "https://github.com/EwoutvanderVelde/SpatialCourse",
        "name":"Mexico city real state price determinants. (April 2023) [GEO, ML]",
        "description":"This study aims to identify the key determinants of property sales prices in Mexico City and understand how they vary across different geographic locations. Thus, the following research questions will be addressed: what are the key determinants for house prices in Mexico City according to Spatial Random Forest (SRF), Geographically Weighted Regression (GWR) and Multiple Geographically Weighted Regression (MGWR)? Specifically, which are the main determinants for each method and how do these results compare with each other?",
        "imgurl":"images/spatial.png"
      },
      {
        "link": "https://github.com/iabrilvzqz/personalisation-for-public-media",
        "name":"Stream platform recommending system (April 2023) [NLP, RS]",
        "description":"A new streaming platform recommendation system was developed from scratch, employing a combination of collaborative filtering and content-based filtering methods to deliver tailor-made and varied suggestions. This innovative system also includes an interactive interface, granting users the ability to adjust the diversity of their recommendations, ensuring a seamless and personalized user experience that matches their unique preferences.",
        "imgurl":"images/rs.ppm"
      },
      {
        "link": "https://discord.com/channels/1127677457030455437/1127677458578153494/1135889833940750419",
        "name":"Fifa World tweets sentiment analysis (Feb 2023) [NLP]",
        "description":"Description: A study involving natural language processing (NLP) was carried out on a dataset of more than 300,000 tweets, utilizing LDA (Latent Dirichlet Allocation) and Hugging Face open source Transformer models",
        "imgurl":"images/sa.png"
      }
    ],
    "testimonials":[
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      },
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      }
    ]
  }
  
  export default resumeData