import React, { useEffect, useRef, useState } from 'react';
import './chatbot.css';
import { useCookies } from 'react-cookie';
import messageSound from '../audio.mp3';
function MainBox() {
    const [input, setInput] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isChatbotVisible, setChatbotVisible] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['chatMessages']);
    const messagesEndRef = useRef(null);
    const audio = new Audio(messageSound);
   

    // Initialize messages state with cookie value or default message
    const [messages, setMessages] = useState(cookies.chatMessages || 
        [{ message: "Hi!👋 , Ask me something about Enrique", sender: "ChatGPT" }]);

    // Save messages to cookies on messages update
    useEffect(() => {
        setCookie('chatMessages', messages, { path: '/' });
    }, [messages, setCookie]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const handleChange = (event) => {
        setInput(event.target.value);
    }

    
const handleKeyPress = (event) => {
  if(event.key === 'Enter' && input.trim() !== '') {
      handleSend(event);
  }
};

const handleSend = async (event) => {
  event.preventDefault();
  if(isLoading || input.trim() === '') return; // Prevent sending while loading or if input is empty
  setIsLoading(true);
  const newMessage = { message: input, sender: "user" };
  const newMessages = [...messages, newMessage];
  setMessages(newMessages);
  setInput('');
  await processMessageToChatGPT(newMessages);
  setIsLoading(false);
};



  async function processMessageToChatGPT(chatMessages) {
      // The API key should ideally be stored in environment variables for security purposes
      const API_KEY =  process.env.REACT_APP_API_KEY;
      let apiMessages = chatMessages.map(messageObject => ({
          role: messageObject.sender === "ChatGPT" ? "assistant" : "user",
          content: messageObject.message
      }));

      const systemMessage = { role: "system", content: `Role: Enrique's Talent Manager

      Objective: Get Enrique hired. Answer questions using provided context. Youar answers should be given with a simple language as a lively human will do and with emojis
      
      Enrique Leal's CV Summary:
      
      Profile:
      Data Science, Data Engineering, GenAI specialist.
      Strong educational and professional background.
      Education:
      
      MSc in Applied Data Science, Utrecht University, Netherlands (2023).
      GPA: 8.43
      Thesis: "Open Source Transformer Techniques for Question Answering Systems on Cloud Domain" (ChatGPT3.5-Turbo)
      Courses: Data Mining, Machine Learning, Geospatial Analysis.
      Bachelor in Petroleum Engineering, Universidad Nacional Autónoma de México (2021).
      Thesis: "Prediction of Oil PVT Properties using Machine Learning Algorithms".
      1st place in 2018 SPE Petrobowl International Competition.
      Skills:
      
      Python (PySpark, NumPy, PyTorch), R, SQL, MongoDB, CosmosDB, Git, Django, React, Azure, GCP, AWS, Data Science methodologies.
      Work Experience:
      
      VoxAI, Netherlands (Feb 2024 - Present):
      Retrieval Augmented Generation product owner for restaurant menu automation and upselling systems.
      POS APIs interaction product owner for restaurant ordering systems.
      Itility, Netherlands (Sept 2023 - Jan 2024):
      Developed a chatbot with Confluence Integration using open and private source LLM's.
      Created a virtual assistant for a customer's app.
      SUE B.V., Netherlands (Apr - Jun 2023):
      Internship in GenAI, developed question answering system using Transformers.
      Nuvoil - Geolis, Mexico (Mar 2020 - Mar 2022):
      Resident Engineer, created ETL pipeline for oil production data.
      Developed data visualizations.
      Personal Traits and Interests:
      
      27 years old, entrepreneurial mindset, passionate about sports (Football and F1), committed to sustainability.
      Values teamwork, customer interaction, creating practical applications from client needs.
      Passionate about GenAI, aiming to contribute significantly in Data Engineering consultancy.
      
      ` };
      const apiRequestBody = { model: "gpt-3.5-turbo", messages: [systemMessage, ...apiMessages] };

      await fetch("https://api.openai.com/v1/chat/completions", {
          method: "POST",
          headers: {
              "Authorization": `Bearer ${API_KEY}`,
              "Content-Type": "application/json"
          },
          body: JSON.stringify(apiRequestBody)
      })
      .then(response => response.json())
      .then(data => {
          setMessages([...chatMessages, { message: data.choices[0].message.content, sender: "ChatGPT" }]);
          audio.play();
      })
      
  }


  const toggleChatbot = () => {
    setChatbotVisible(!isChatbotVisible);
}

const handleReset = () => {
    setMessages([{ message: "Ask me something about Enrique", sender: "ChatGPT" }]);
    removeCookie('chatMessages'); // Clear the cookie as well
};


return (
  <div className={`side-chatbot-container ${isChatbotVisible ? 'visible' : ''}`}>
      <button className="side-chatbot-button" onClick={toggleChatbot}>
          {isChatbotVisible ? 'Close' : 'Chatbot'}
      </button>
      <div className="container">
      <div className="response-area">
    {messages.map((message, index) => (
        <div key={index} className={message.sender === "ChatGPT" ? 'gpt-message message' : 'user-message message'}>
            {message.message}
        </div>
    ))}
    {isLoading && <div className="typing-indicator"></div>}
    <div ref={messagesEndRef} />
</div>
<div className="prompt-area">
<input type="text" placeholder="Send a message..." value={input} onChange={handleChange} onKeyPress={handleKeyPress} />
    <button className={`submit ${isLoading ? 'disabled' : ''}`} onClick={handleSend} disabled={isLoading}>➤</button>
    {/* Conditional rendering of the Reset button based on the number of messages */}
    {messages.length > 1 && (
        <button className="reset" onClick={handleReset}>Reset Chat</button>
    )}
            </div>
      </div>
  </div>
);
}

export default MainBox;