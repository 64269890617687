import React, { Component } from 'react';
import './Portfolio.css'; // Import your CSS file

export default class Portfolio extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="portfolio">
        <div className="row">
          <div className="twelve columns collapsed">
            <h1>Check Out Some of My Works.</h1>
            <div id="portfolio-wrapper" className="portfolio-flex-container">
              {
                resumeData.portfolio && resumeData.portfolio.map((item) => {
                  return (
                    <div className="portfolio-flex-item">
                      <div className="item-wrap">
                        <a href={item.link}>
                          <img src={`${item.imgurl}`} alt={item.name} className="item-img"/>
                          <div className="overlay">
                            <div className="portfolio-item-meta">
                              <h5>{item.name}</h5>
                              <p>{item.description.length > 100 ? item.description.substring(0, 100) + '...' : item.description}</p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </section>
    );
  }
}
