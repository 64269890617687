import React, { Component } from 'react';
import './About.css'; // Import your CSS file
export default class About extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="about">
         <div className="row">

         <div className="three columns">
    <img className="profile-pic"  src="images/pp.jpeg" alt="Profile Picture" />
    <a href="images/jelc_cv.pdf" download>
        <button type="button" className="download-cv-button">
            Download CV
            <span>↓</span> {/* You can replace this with an arrow icon */}
        </button>
    </a>
</div>
            <div className="nine columns main-col">

               <h2>About Me</h2>
               <p>
               {
                 resumeData.aboutme
               }
               </p>

               <div className="row">

                  <div className="columns contact-details">

                  <h2>Contact Details</h2>
                  <p className="address">
       						<span>{resumeData.name}</span>
                     <br></br>
       						   <span>
                     {resumeData.address}
                    </span>
                    <br></br>
                    <span>{resumeData.website}</span>
       					   </p>

                        
                  </div>
               </div>
            </div>
         </div>
      </section>
    );
  }
}